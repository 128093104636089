export default {
    editItem(data) {
        this.form.edit(data);
        this.getLedgerHeads();
        this.getBanks();
        this.form.particulars = data.ledger_accounts;
        this.payment_mode_type = data.details
        let i = 0;
        for (i = 0; i < data.ledger_accounts.length; i++) {
            if (data.ledger_accounts[i].dr > 0) {
                this.form.particulars[i].total_amount = data.ledger_accounts[i].dr
                this.form.particulars[i].ledger_head_id = data.ledger_accounts[i].ledger_head_id
            }
            if (data.ledger_accounts[i].cr > 0) {
                this.form.particulars[i].total_amount = data.ledger_accounts[i].cr
                this.form.particulars[i].ledger_head_id = data.ledger_accounts[i].ledger_head_id

            }
        }
    },

    deleteItem(journalEntry) {
        this.form.delete(journalEntry.id);
    },

    storeLedger() {
        this.ledgerForm.store().then(({data}) => {
            this.getLedgerHeads(data.data.id);
        })
    },

    getJournalTypes() {
        this.$rest.get('/api/journal-type').then(({data}) => {
            this.journalTypes = data.data.map(item => ({value: item.id, text: item.name}));
        })
    },
    getAccountHeads() {
        this.$rest.get('/api/account-head').then(({data}) => {
            this.accountHeads = data.data.map(item => ({value: item.id, text: item.name}));
        })
    },

    getLedgerHeads(id) {
        this.$rest.get('/api/ledger-head').then(({data}) => {
            this.ledgerHeads = data.data.map(item => ({value: item.id, text: item.name}));
            if (![undefined, null].includes(id)) {
                let len = this.form.particulars.length;
                if (len > 0) this.form.particulars[len - 1].ledger_head_id = id;
                else this.form.particulars[0].ledger_head_id = id;
            }
        })
    },

    submit() {
        this.form.frmDate = this.dateFormat(this.form.frmDate);
        this.form.toDate = this.dateFormat(this.form.toDate);
        let url = '/api/payment-receipt?journal_type_id='+ this.form.journal_type_id + '&frmDate=' + this.form.frmDate + '&toDate=' + this.form.toDate;
        if(this.form.serial_no) url = url+ '&serialNo=' +this.form.serial_no;
        if(!this.form.journal_type_id){
            this.$events.fire('notification', {message:'Please select Journal Type',status:'error'});
        }
        else {
            this.$rest.get(url).then(res => {
                if(res.data.data.length > 0 ) this.download = true;
                else this.download = false;
                this.form.items = res.data;
            });
        }
    },

    addRow: function () {
        if ([undefined, null].includes(this.form.particulars)) {
            this.form.particulars = [{ledger_head_id: null, drcr: 'dr', amount: 0}];
        } else {
            this.form.particulars.push({ledger_head_id: null, drcr: 'cr', amount: 0});
        }
    },
    removeElement: function (row) {
        var index = this.form.particulars.indexOf(row);
        this.form.particulars.splice(index, 1);
    },

    dateFormat(data) {
        if (data.length === 8) return data = data.substr(0, 4) + '-' + data[4] + data[5] + '-' + data.slice(-2);
        else return data;
    },

    setEntryDate() {
        if (this.form.frmDate == null) {
            let date = '';//this.$converter.todayNP();
            // this.form.frmDate = `${date.year}-${date.month.pad(2)}-${date.day.pad(2)}`;
        }
        if (this.form.toDate == null) {
            // let date = this.$converter.todayNP();
            // this.form.toDate = `${date.year}-${date.month.pad(2)}-${date.day.pad(2)}`;
        }
    },

    store() {
        this.form.payment_mode = this.payment_mode_type;
        this.form.general_type = 'receipt';
        if (this.form.entry_date.length === 8) {
            this.form.entry_date = this.form.entry_date.substr(0, 4) + '-' + this.form.entry_date[4] + this.form.entry_date[5] + '-' + this.form.entry_date.slice(-2);
        }
        this.form.store();
    },


    ledgerChange(data) {
        let head = this.ledgerHeads.find(item => item.value == data.ledger_head_id);
        data.ledger_head_name = head.text;
    },

    lazySum(row) {
        return new Promise((resolve, reject) => {
            row.net_amount = parseFloat(row.total_amount) + parseFloat(row.tax);
            resolve(row);
        })
    },

    sumNetAmount(item) {
        if ([undefined, null].includes(item)) {
            if (typeof this.form.particulars == 'object' && this.form.particulars !== null) this.payment_mode_type.final_amount = this.form.particulars.map(it => parseFloat(it.net_amount)).reduce((x, y) => x + y, 0)
        } else {
            this.lazySum(item).then(response => {
                if (isNaN(item.net_amount)) item.net_amount = 0
                this.payment_mode_type.final_amount = this.form.particulars.map(it => parseFloat(it.net_amount)).reduce((x, y) => x + y, 0)
            })
        }
    },
    paymentModeChange(d) {
        let pM = this.paymentMode.find(
            item => item.value == d
        );
        this.payment_mode_type.mode = pM.text;

        if (this.payment_mode_type.cheque_date == null) {
            let date = this.$converter.todayNP();
            this.payment_mode_type.cheque_date = `${date.year}-${date.month.pad(2)}-${date.day.pad(2)}`;
        }
    },

    getPaymentMode() {
        this.$rest.get('/api/ledger-head/cash-bank').then(({data}) => {
            this.paymentMode = data.map(item => ({value: item.id, text: item.name}));
        });
    },
    getBanks() {
        this.$rest.get('/api/ledger-head/banks').then(({data}) => {
            this.banks = data.map(item => ({value: item.id, text: item.name}));
        });
    },

    voucherChange(item) {
        this.$rest.get('/api/payment-receipt/serial?journal_type_id=' + item).then(({data}) => {
            this.form.serial_no = data.serial;
            this.form.jt_id = item;
        });
    }
}
