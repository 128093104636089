<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <v-icon>layers</v-icon>
            Payment Reciept
            <v-spacer></v-spacer>
            <!--<v-flex xs12 sm2>-->
            <!--<v-btn color="primary" dark outline @click='submit' class="text-xs-right":disabled="!form.journal_type_id">-->
            <!--<v-icon small>add</v-icon>-->
            <!--Search-->
            <!--</v-btn>-->
            <!--</v-flex>-->
            <add-button
              permission="section-create"
              @action="submit"
              icon="search"
              >Search</add-button
            >
          </v-card-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="form.dialog" max-width="850px" persistent>
            <v-card>
              <v-card-title class="primary white--text">
                <span class="title">{{ journalType }}</span>
              </v-card-title>

              <v-card-text class="has-spinner">
                <!--<spinner light="light" :loading="form.loading"/>-->
                <v-container fluid grid-list-sm pa-0>
                  <v-form
                    ref="form"
                    @submit.prevent="store"
                    @keydown.native="form.errors.clear($event.target.name)"
                    @keyup.enter="store"
                    lazy-validation
                  >
                    <v-layout wrap>
                      <v-flex xs12 sm3>
                        <v-text-field
                          :readonly="form.loading"
                          v-model="form.serial_no"
                          name="serial_number"
                          label="Serial No"
                          :height="25"
                          disabled
                        >
                        </v-text-field>
                      </v-flex>

                      <v-flex xs12 sm3>
                        <v-text-field
                          label="Date"
                          v-model="form.entry_date"
                          name="entry_date"
                          prepend-icon="event"
                          mask="####-##-##"
                          placeholder="YYYY-MM-DD"
                          :error-messages="form.errors.get('entry_date')"
                          :height="25"
                          :readonly="form.loading"
                          disabled
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm3>
                        <v-select
                          v-model="payment_mode_type.ledger_head_id"
                          :items="paymentMode"
                          label="Select Payment Mode"
                          name="payment_mode_type"
                          :height="25"
                          @change="paymentModeChange"
                          :readonly="form.loading"
                        />
                      </v-flex>
                      <v-flex xs12 sm3 v-if="payment_mode_type">
                        <v-text-field
                          :readonly="form.loading"
                          v-model="payment_mode_type.cheque_no"
                          name="cheque_no"
                          label="Check No"
                          :height="25"
                          v-if="this.payment_mode_type.mode == 'Bank'"
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex xs12 sm2 v-if="payment_mode_type">
                        <v-text-field
                          label="Check Date"
                          v-model="payment_mode_type.cheque_date"
                          prepend-icon="event"
                          mask="####-##-##"
                          placeholder="YYYY-MM-DD"
                          :height="25"
                          :readonly="form.loading"
                          v-if="this.payment_mode_type.mode == 'Bank'"
                        />
                      </v-flex>
                      <v-flex xs12 sm2>
                        <v-select
                          v-model="payment_mode_type.bank_ledger_head_id"
                          :items="banks"
                          label="Select Banks"
                          name="payment_mode_type.bank_ledger_head_id"
                          :height="25"
                          v-if="this.payment_mode_type.mode == 'Bank'"
                          :readonly="form.loading"
                        />
                      </v-flex>
                      <v-flex xs12 sm1 text-xs-right>
                        <v-btn @click="addRow" outline :disabled="form.loading">
                          <v-icon small class="mr-2"> add</v-icon>
                          add
                        </v-btn>
                      </v-flex>
                      <v-spacer></v-spacer>
                      <v-layout
                        row
                        wrap
                        v-for="(row, key) in form.particulars"
                        :key="key"
                      >
                        <v-flex xs12 sm5>
                          <v-select
                            :readonly="form.loading"
                            v-model="row.ledger_head_id"
                            :items="ledgerHeads"
                            label="Select Ledger Head"
                            @change="ledgerChange(row)"
                            name="ledger_head_id"
                            :height="25"
                          />
                        </v-flex>
                        <v-flex xs12 sm5>
                          <v-text-field
                            :readonly="form.loading"
                            v-model="row.total_amount"
                            type="number"
                            name="amount"
                            label="Total Amount"
                            :height="25"
                            @keyup="sumNetAmount(row)"
                          ></v-text-field>
                        </v-flex>
                        <!--<v-flex xs12 sm2>-->
                        <!--<v-text-field :readonly="form.loading" v-model="row.tax" type="number" name="tax" label="Tax Amount" :height="25" @keyup="sumNetAmount(row)" />-->
                        <!--</v-flex>-->
                        <!--<v-flex xs12 sm3>-->
                        <!--<v-text-field :readonly="true" :disabled="true" v-model="row.net_amount" type="number" name="net_amount" label="Net Amount" :height="25"/>-->
                        <!--</v-flex>-->
                        <v-flex xs12 sm1 text-xs-right>
                          <v-btn
                            :disabled="form.loading"
                            @click="removeElement(row)"
                            class="error"
                            outline
                            icon
                            small
                          >
                            <v-icon small class="error--text">remove</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>

                      <div
                        v-if="form.particulars && form.particulars.length"
                        style="
                          width: 100%;
                          text-align: right;
                          border-top: 1px solid #cacaca;
                          padding-top: 10px;
                        "
                      >
                        <v-chip label outline color="primary"
                          >Total Amount:
                          <strong
                            >&nbsp;{{ payment_mode_type.final_amount }}</strong
                          ></v-chip
                        >
                      </div>

                      <v-flex xs12>
                        <v-textarea
                          :readonly="form.loading"
                          v-model="form.narration"
                          label="Narration"
                          name="narration"
                          :error-messages="form.errors.get('narration')"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <!--<v-btn slot="activator" color="primary" dark class="mb-2" outline @click="ledgerForm.dialog = true" :disabled="form.loading"><v-icon small>add</v-icon> &nbsp;Ledger Head</v-btn>-->
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  :disabled="form.loading"
                  @click="form.reset()"
                  outline
                  >Cancel
                </v-btn>
                <v-btn
                  color="info"
                  :disabled="form.errors.any() || form.loading"
                  @click="store"
                  outline
                  >Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
        <v-card>
          <v-card-text>
            <v-form
              ref="form"
              @submit.prevent="store"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="store"
              lazy-validation
            >
              <v-layout wrap>
                <v-flex xs12 sm3>
                  <v-select
                    :readonly="form.loading"
                    v-model="form.journal_type_id"
                    :items="journalTypes"
                    label="Select Voucher Type"
                    name="journal_type_id"
                    :height="25"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <v-text-field
                    label="From Date"
                    v-model="form.frmDate"
                    name="frmDate"
                    prepend-icon="event"
                    mask="####-##-##"
                    placeholder="YYYY-MM-DD"
                    :error-messages="form.errors.get('frmDate')"
                    :height="25"
                    :readonly="form.loading"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <v-text-field
                    label="To date"
                    v-model="form.toDate"
                    name="toDate"
                    prepend-icon="event"
                    mask="####-##-##"
                    placeholder="YYYY-MM-DD"
                    :error-messages="form.errors.get('toDate')"
                    :height="25"
                    :readonly="form.loading"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <v-text-field
                    label="Serial Number"
                    v-model="form.serial_no"
                    name="serial_no"
                    :error-messages="form.errors.get('serial_no')"
                    :height="25"
                    :readonly="form.loading"
                  />
                </v-flex>
              </v-layout>
            </v-form>
            <transition
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
              <v-btn
                v-if="this.download"
                fab
                small
                bottom
                right
                absolute
                @click.native.stop="printReport"
                style="z-index: 1; background: blue; bottom: 13px; right: 7px"
                dark
              >
                <v-icon dark>cloud_download</v-icon>
              </v-btn>
            </transition>
          </v-card-text>
        </v-card>

        <!-- Add Ledger Head -->
        <v-dialog v-model="ledgerForm.dialog" max-width="500px" persistent>
          <v-card>
            <v-card-title class="primary white--text">
              <span class="title">Ledger Head Information</span>
            </v-card-title>

            <v-card-text class="has-spinner">
              <!--<spinner light="light" :loading="ledgerForm.loading"/>-->
              <v-container fluid grid-list-sm pa-0>
                <v-form
                  ref="form"
                  @submit.prevent="store"
                  @keydown.native="ledgerForm.errors.clear($event.target.name)"
                  @keyup.enter="store"
                  lazy-validation
                >
                  <v-layout wrap>
                    <v-flex xs12 sm6>
                      <v-text-field
                        v-model="ledgerForm.name"
                        name="name"
                        label="Name"
                        :error-messages="ledgerForm.errors.get('name')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm6 d-flex>
                      <v-select
                        v-model="ledgerForm.account_head_id"
                        name="account_head_id"
                        :items="accountHeads"
                        label="Select Account Head"
                        :error-messages="
                          ledgerForm.errors.get('account_head_id')
                        "
                        :height="25"
                      />
                    </v-flex>
                    <v-flex xs12 sm12>
                      <v-textarea
                        v-model="ledgerForm.description"
                        name="description"
                        label="Description"
                        :error-messages="ledgerForm.errors.get('description')"
                        autocomplete="off"
                      />
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" @click="ledgerForm.reset()" outline
                >Cancel</v-btn
              >
              <v-btn
                color="info"
                :disabled="ledgerForm.errors.any() || ledgerForm.loading"
                @click="storeLedger"
                outline
                >Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
          :headers="headers"
          :items="form.items.data"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <td width="20%">{{ props.item.journal_type.name }}</td>
            <td>{{ props.item.voucher_no }}</td>
            <td>{{ props.item.serial_no }}</td>
            <td class="text-xs-left" width="20%">
              {{ props.item.entry_date }}
            </td>
            <td class="text-xs-left" width="40%">
              <tr
                v-for="(particular, i) in props.item.ledger_accounts"
                :key="i"
              >
                <td
                  v-if="particular.dr > 0"
                  style="position: absolute; margin-left: -25px"
                >
                  {{ particular.ledger_head }} (Dr.)
                </td>

                <td
                  v-else-if="particular.cr > 0"
                  style="position: absolute; margin-left: 35px"
                >
                  To,
                  {{ particular.ledger_head }}
                </td>

                <br />
              </tr>
              <!--<span ></span>-->
              <!--<span v-else style="margin-left: 65px; position: absolute">{{particular.ledger_head}}</span>-->
              <!--<br/>-->
              <span
                ><i> (Narration: {{ props.item.narration }})</i></span
              >
            </td>
            <!--<td class="text-xs-center">{{props.item.lf_id}}</td>-->
            <td>
              <tr
                v-for="(particular, i) in props.item.ledger_accounts"
                :key="i"
              >
                <td
                  v-if="parseInt(particular.dr) > 0"
                  style="position: absolute; margin-top: -12px"
                >
                  {{ particular.dr }}
                </td>
                <br />
              </tr>
            </td>
            <td>
              <tr
                v-for="(particular, i) in props.item.ledger_accounts"
                :key="i"
              >
                <td
                  v-if="parseInt(particular.cr) > 0"
                  style="position: absolute; margin-top: -12px"
                >
                  {{ particular.cr }}
                </td>
                <br />
              </tr>
            </td>
            <td class="text-xs-center">{{ props.item.user }}</td>
            <td class="justify-right text-xs-right">
              <v-icon small class="mr-2" @click="editItem(props.item)"
                >edit</v-icon
              >
            </td>
          </template>
        </v-data-table>
        <!--<notification :notification="form.notification" />-->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import data from "./modules/data";
import computed from "./modules/computed";
import mounted from "./modules/mounted";
import created from "./modules/created";
import methods from "./modules/methods";
import watch from "./modules/watch";

// import Spinner from '@/components/Partials/Spinner'
// import Notification from '@/components/Partials/Notification'

export default {
  name: "PaymentRecieveList",
  // components: {Spinner,Notification},
  data: () => data,
  mounted: mounted,
  computed: computed,
  methods: methods,
  created: created,
  watch: watch,
};
</script>
<style lang="scss" scoped></style>
