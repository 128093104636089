import Form from '@/library/Form';
    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();
    
export default {
    form: new Form({
            general_type: '',
            narration: '',
            payment_mode: {
                ledger_head_id: ''
            },
            frmDate: nd.format('YYYY-MM-DD'),
            toDate: nd.format('YYYY-MM-DD'),

            jt_id: '', // for validation check
            serial_no: '',
            entry_date: '',
            particulars: [{
                ledger_head_id: '',
                ledger_head_name: '',
                total_amount: 0,
                tax: 0,
                net_amount: 0
            }],
            journal_type_id: ''
        },
        '/api/payment-receipt'
    ),
    payment_mode_type: {
        mode: 'cash',
        cheque_no: '',
        cheque_date: '',
        journal_type_id: '',
        ledger_head_id: '',
        bank_ledger_head_id: '',
        final_amount: 0
    },
    headers: [
        {text: 'Journal Type', value: 'journal_type', align: "left", sortable: false},
        {text: 'V No', value: 'voucher_no', align: "left", sortable: false},
        {text: 'Serial No', value: 'serial_no', align: "left", sortable: false},
        {text: 'Date', value: 'nepali_date', align: "left", sortable: false},
        {text: 'Particular', value: 'particular', align: "left", sortable: false},
        // { text: 'LF Id', value: 'lf_id', align: "center" },
        {text: 'Dr.', value: 'dr', align: "right", sortable: false},
        {text: 'Cr.', value: 'cr', align: "right", sortable: false},
        {text: 'User.', value: 'user', align: "center", sortable: false},
        {text: 'Actions', value: 'action', sortable: false, align: "right"}
    ],
    pr_type: [],
    download: false,
    journalType: '',

    ledgerForm: new Form({
        name: '',
        description: '',
        account_head_id: ''
    }, [], '/ledger-head'),

    ledgerHeads: [], /** Ledger Heads **/

    journalTypes: [], /** Journal Types **/

    accountHeads: [], /** Account Heads **/

    banks: [], /** Banks **/

    drcrList: [
        {value: 'dr', text: 'DR'},
        {value: 'cr', text: 'CR'}
    ],

    paymentMode: [],
}
