export default function created() {
	/** Get today's journals **/
    this.form.get();

    // this.form.payment_mode.mode = 'cash';

    // /** Get Account Heads **/
    // this.getJournalTypes();

    // /** Get Account Heads **/
    // this.getLedgerHeads();

    // /** Get Account Heads **/
    // this.getAccountHeads();

    // // this.$converter.toNP(2000,5,12));
    // // this.$converter.toEN(2047,1,29);

    // // console.log(this.$converter.todayEN());

    // this.setEntryDate();

}
